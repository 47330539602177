export default {
    prefix: 'custom',
    iconName: 'phone-2',
    icon: [
        14.592,
        20.611,
        [],
        null,
        'M4.842 0L2.275 1.191c-6.71 3.497 2.9 22.195 9.74 19.069.099-.047-2.244 1.102 2.405-1.174l.172-.086-3.63-7.004-.165.082c-4.14 2.04-2.322 1.155-2.164 1.078-.808-.092-2.945-4.041-2.584-5.029L8.41 6.965zm-.94 2.684l1.72 3.357-.68.338c-2.917 1.563 1.532 10.104 4.42 8.691l.7-.34 1.734 3.35-.639.315c-3.486 1.592-9.244-7.814-9.152-12.81.022-1.229.404-2.143 1.203-2.56z'
    ]
};
