export default {
    prefix: 'custom',
    iconName: 'user',
    icon: [
        28.004,
        28.072,
        [],
        null,
        'M14.023 0a8.612 8.612 0 00-5.785 15.03A12.306 12.306 0 000 26.63a1.474 1.474 0 002.947 0 9.368 9.368 0 019.364-9.361h3.386a9.368 9.368 0 019.36 9.359 1.474 1.474 0 002.947 0 12.306 12.306 0 00-8.277-11.602A8.612 8.612 0 0014.023 0zm-.113 3.02a5.636 5.636 0 01.07 0 5.636 5.636 0 11-5.636 5.636A5.636 5.636 0 0113.91 3.02z'
    ]
};
