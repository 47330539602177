export default {
    prefix: 'custom',
    iconName: 'pie-chart',
    icon: [
        60.63,
        60.63,
        [],
        null,
        'M30.316 0A30.315 30.315 0 1060.63 30.314 30.4 30.4 0 0030.316 0zm-2.022 4.143V29.91l-10.71 23.344a26.113 26.113 0 01-13.542-22.94A26.247 26.247 0 0128.294 4.143zm4.043 0a26.391 26.391 0 0124.15 24.15h-24.15zm5.457 28.193h18.693a26.427 26.427 0 01-2.525 9.4zm-6.574.809L51.94 45.17a26.124 26.124 0 01-21.624 11.418 26.881 26.881 0 01-9.096-1.617z'
    ]
};
