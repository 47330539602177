export default {
    prefix: 'custom',
    iconName: 'lock',
    icon: [
        18.891,
        23.612,
        [],
        null,
        'M9.367 0a5.903 5.903 0 00-5.824 5.903v3.545H0v14.164h18.89V9.444h-3.542V5.903A5.903 5.903 0 009.368 0zm.043 2.42a3.542 3.542 0 013.576 3.483v3.54H5.902v-3.54A3.542 3.542 0 019.41 2.42zm-7.049 9.383h14.166v9.444H2.361z'
    ]
};
