export default {
    prefix: 'custom',
    iconName: 'umbrella',
    icon: [
        62.04,
        56.887,
        [],
        null,
        'M28.75 0a33.7 33.7 0 00-7.26.833C11.377 3.15 5.688 8.627 0 16.844c7.163.206 11.376 1.264 15.59 8.006 5.478.21 9.48 2.106 12.64 6.32L16.012 52.026c-2.317 3.586-8.217 1.475-5.057-4.213 1.053-1.686-1.685-3.375-2.738-1.475-5.688 9.691 6.746 14.115 10.746 7.163l12.01-20.647c5.058.422 8.85 2.949 11.8 7.584 7.585.211 10.746 3.372 14.538 9.482 4.424-9.06 6.32-16.646 3.16-26.546A33.383 33.383 0 0046.773 5.256l1.051-1.896c1.053-1.686-1.685-3.375-2.738-1.475l-1.053 1.686A33.7 33.7 0 0028.75 0zm.594 3.28c12.545.246 24.228 8.492 27.96 20.935v.004a29.915 29.915 0 01-.42 19.172 19.493 19.493 0 00-12.43-6.11 17.977 17.977 0 00-13.483-7.8 18.761 18.761 0 00-13.696-7.793 17.119 17.119 0 00-11.377-7.586A29.447 29.447 0 0122.33 3.99a29.24 29.24 0 017.014-.71z'
    ]
};
