document.addEventListener('DOMContentLoaded', function() {
    const toplayer = document.querySelector('.toplayer')
    function setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}
	function checkCookie(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
        return null;
    }
    
    if(toplayer){
        if (!checkCookie('christmas_toplayer')) {
            toplayer.style.display = 'flex'
            setCookie('christmas_toplayer', 'true', 3);
        }
        toplayer.addEventListener('click', function() {
            toplayer.style.display = 'none'
        })
    }
});
