export default {
    prefix: 'custom',
    iconName: 'phone',
    icon: [
        27.199,
        27.25,
        [],
        null,
        'M6.341 0a2.686 2.686 0 00-1.475.441l-3.283 2.19A3.564 3.564 0 00.007 5.816a18.36 18.36 0 00.418 3.122A25.247 25.247 0 007.15 20.105a25.248 25.248 0 0011.166 6.727 18.844 18.844 0 003.123.418h.224a3.541 3.541 0 002.961-1.61l2.156-3.314a2.62 2.62 0 00.323-2.125 2.642 2.642 0 00-1.319-1.672l-5.181-2.672a2.7 2.7 0 00-3.346.74l-1.385 1.77a16.945 16.945 0 01-3.926-3.023A17.516 17.516 0 018.89 11.32l1.77-1.384a2.727 2.727 0 00.74-3.346L8.728 1.44A2.669 2.669 0 007.023.087 2.686 2.686 0 006.34 0zm.006 1.955a.408.408 0 01.193.031.738.738 0 01.483.387l2.671 5.148a.786.786 0 01-.193.934l-3.057 2.38.385.71a19.382 19.382 0 003.801 5.18 20.065 20.065 0 005.117 3.732l.707.387 2.414-3.088a.744.744 0 01.934-.194l5.182 2.67a.738.738 0 01.384.485 1.242 1.242 0 01-.195.58l-2.156 3.314a1.64 1.64 0 01-1.448.74 15.013 15.013 0 01-2.767-.353 23.1 23.1 0 01-10.268-6.244A23.574 23.574 0 012.292 8.488a15.016 15.016 0 01-.353-2.767 1.613 1.613 0 01.707-1.45l3.283-2.187a.779.779 0 01.418-.129z'
    ]
};
