import Swiper, { Navigation, Pagination, Lazy, Autoplay, A11y } from 'swiper';

function initSlider(sliderID) {
    const sliderLogo = new Swiper(sliderID, {
        modules: [Navigation, Pagination, Lazy, Autoplay, A11y],
        slidesPerView: 3,
        spaceBetween: 20,
        speed: 700,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        preloadImages: false,
        watchSlidesProgress: true,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        loop: true,
        navigation: {
            nextEl: '#slider-logo-arrow-next',
            prevEl: '#slider-logo-arrow-prev'
        }
    });
}

if (document.getElementById('slider-logo-news')) {
    initSlider('#slider-logo-news');
}
