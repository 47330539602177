export default {
    prefix: 'custom',
    iconName: 'money',
    icon: [
        17.193,
        12.245,
        [],
        null,
        'M0 0v9.915h2.402v2.33h14.791V2.33h-2.402V0H0zm.77.77h13.25v8.373H.77V.77zm6.626 1.243C5.802 2.01 4.756 3.115 4.503 4.38c-.253 1.265.289 2.687 1.76 3.298 1.47.61 2.859-.01 3.577-1.082.717-1.072.762-2.593-.365-3.72a2.943 2.943 0 00-2.079-.863zm0 .717c.59 0 1.155.236 1.572.654.878.879.818 2.003.275 2.814-.543.81-1.56 1.293-2.706.818-1.145-.476-1.52-1.539-1.33-2.495.192-.957.946-1.793 2.189-1.79zm7.395.371h1.632v8.373H3.173v-1.56H14.79V3.1z'
    ]
};
