export default {
    prefix: 'custom',
    iconName: 'email',
    icon: [
        18.379,
        13.879,
        [],
        null,
        'M0 0v13.879h18.379V0zm2.586 1.879h13.21L9.19 7.232zm-.707 1.846l7.31 5.923L16.5 3.725V12H1.879z'
    ]
};
