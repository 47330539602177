export default {
    prefix: 'custom',
    iconName: 'bank',
    icon: [
        32,
        32.813,
        [],
        null,
        'M16.023 0a2.824 2.824 0 00-1.015.18L.916 5.465A1.412 1.412 0 000 6.785v2.028a1.412 1.412 0 001.412 1.412h.47v2.824a.941.941 0 00.942.941v9.412a.941.941 0 00-.941.942v2.824H.94a.941.941 0 00-.941.94v3.763a.941.941 0 00.941.942h30.12a.941.941 0 00.94-.943v-3.763a.941.941 0 00-.941-.942h-.942v-2.822a.941.941 0 00-.94-.942V13.99a.941.941 0 00.94-.941v-2.824h.473A1.412 1.412 0 0032 8.813V6.785a1.412 1.412 0 00-.916-1.32L16.99.18a2.824 2.824 0 00-.967-.18zm-.033 1.883a.942.942 0 01.022 0 .942.942 0 01.318.059l13.787 5.17v1.23H1.883v-1.23l13.787-5.17a.942.942 0 01.32-.06zM3.766 10.225h3.763v1.883H3.766zm5.646 0h2.822v2.824a.941.941 0 00.942.941v9.412a.941.941 0 00-.942.942v2.822l-2.822.002v-2.824a.941.941 0 00-.941-.942V13.99a.941.941 0 00.941-.941zm4.705 0h3.766v1.883h-3.766zm5.649 0h2.822v2.824a.941.941 0 00.941.941v9.412a.941.941 0 00-.941.942v2.822l-2.822.002v-2.824a.941.941 0 00-.942-.942V13.99a.941.941 0 00.942-.941zm4.705 0h3.765v1.883h-3.765zm-9.412 3.763l1.882.002v9.41H15.06zm-10.354.002h1.883v9.41l-1.883.003zm20.707 0h1.883v9.412l-1.883-.002zM3.766 25.283h3.763v1.883H3.766zm10.351 0h3.766v1.883h-3.766zm10.354 0h3.763v1.883h-3.763zM1.883 29.05h28.234v1.883H1.883z'
    ]
};
