export default {
    prefix: 'custom',
    iconName: 'pay',
    icon: [
        60.045,
        58.171,
        [],
        null,
        'M37.307 0a13.139 13.139 0 00-12.924 13.14 13.154 13.154 0 0013.139 13.138A13.14 13.14 0 1037.307 0zm.215 3.754a9.385 9.385 0 11-9.385 9.385 9.4 9.4 0 019.385-9.385zM52.252 24.4a6.609 6.609 0 00-4.015 1.383l-12.6 9.8a7.512 7.512 0 00-7.5-7.431H15.908A13.047 13.047 0 006.62 32L.533 38.087a1.877 1.877 0 002.655 2.655l6.088-6.086a9.315 9.315 0 016.634-2.75h12.225a3.758 3.758 0 013.754 3.754v1.82a5.624 5.624 0 01-.701.057h-8.68a1.878 1.878 0 000 3.755h8.686a9.431 9.431 0 005.761-1.978l13.586-10.567a2.836 2.836 0 011.73-.593h.2a2.8 2.8 0 011.992.824l1.05 1.053-16.02 16.02a9.315 9.315 0 01-6.637 2.75H17.008a11.178 11.178 0 00-7.963 3.3l-2.88 2.88a1.877 1.877 0 002.654 2.655l2.882-2.88a7.451 7.451 0 015.309-2.202h15.848a13.046 13.046 0 009.289-3.85l17.35-17.347a1.876 1.876 0 00.548-1.326v-.002a1.877 1.877 0 00-.55-1.328l-2.38-2.38a6.53 6.53 0 00-4.644-1.923h-.201a6.609 6.609 0 00-.018 0z'
    ]
};
