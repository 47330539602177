export default {
    prefix: 'custom',
    iconName: 'wallet',
    icon: [
        32.904,
        31.086,
        [],
        null,
        'M12.672 0L8.645 8.053h-3.16v2.19H30.71v3.288h2.19v-3.289a2.2 2.2 0 00-2.19-2.19h-1.94zm-8.3 2.568A4.392 4.392 0 000 6.955v19.742a4.392 4.392 0 004.387 4.389h26.318A2.2 2.2 0 0032.9 28.89V25.6h-2.19v3.292H4.388a2.2 2.2 0 01-2.194-2.196V6.955a2.2 2.2 0 012.194-2.193h3.29V2.568h-3.29a4.392 4.392 0 00-.016 0zm20.855 0v2.194h5.484V2.568zm-11.575.373l10.22 5.112H11.1zm12.67 12.788a3.294 3.294 0 00-3.289 3.289v1.1a3.294 3.294 0 003.29 3.29h4.388a2.2 2.2 0 002.193-2.193v-3.291H32.9a2.2 2.2 0 00-2.19-2.195zm0 2.195h4.39v3.3h-4.39a1.1 1.1 0 01-1.1-1.1v-1.1a1.1 1.1 0 011.1-1.1z'
    ]
};
