export default {
    prefix: 'custom',
    iconName: 'smile',
    icon: [
        59.581,
        59.566,
        [],
        null,
        'M29.823 0C14.872-.017 2.225 11.051.26 25.87c-1.963 14.811 7.367 28.791 21.801 32.67 14.434 3.88 29.515-3.538 35.242-17.34 5.729-13.806.334-29.725-12.611-37.205A29.75 29.75 0 0029.822 0zm.084 3.654a26.067 26.067 0 01-.084 52.133C15.423 55.763 3.776 44.08 3.799 29.68c.024-14.4 11.708-26.05 26.108-26.026zM21.99 21.098c-2.728 0-4.099 3.316-2.17 5.244 1.929 1.928 5.243.553 5.242-2.174 0-1.693-1.38-3.07-3.072-3.07zm15.666 0c-2.728-.001-4.103 3.315-2.174 5.244 1.928 1.928 5.245.554 5.244-2.174a3.075 3.075 0 00-3.07-3.07zm-26.211 9.261c.306 9.91 8.393 17.868 18.377 17.868 9.983 0 18.07-7.958 18.377-17.868h-3.723c-.37 7.81-6.77 14.022-14.656 14.022-7.887 0-14.284-6.211-14.654-14.022z'
    ]
};
