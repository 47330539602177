export default {
    prefix: 'custom',
    iconName: 'card',
    icon: [
        62.195,
        41.914,
        [],
        null,
        'M0 0v41.914h62.195V0zm2.701 2.701h56.791v4.065H2.705zm0 6.77h56.791v5.406H2.705zm0 8.111h56.791v21.633H2.705zm38.955 3.998a6.76 6.76 0 00-5.705 3.461c-1.329 2.768-.838 4.812.145 6.834a6.76 6.76 0 001.818 1.955l-.002-.006c2.596 1.866 5.769 1.563 7.984-.08.026.02.05.043.077.063l-.002-.006c4.454 3.347 10.817.169 10.816-5.403a6.76 6.76 0 00-6.812-6.76 6.753 6.753 0 00-3.99 1.35 6.73 6.73 0 00-4.329-1.408zm.035 2.684c2.749-.096 4.8 2.5 4.07 5.152-.728 2.652-3.819 3.836-6.132 2.35l-.002-.006c-2.462-2.343-2.134-4.92-.143-6.752a4.081 4.081 0 012.207-.744zm8.26.058c2.75-.037 4.746 2.603 3.961 5.239-.785 2.635-3.9 3.754-6.182 2.218l-.002-.006a6.705 6.705 0 00.59-5.394 6.684 6.684 0 00-.148-.432c-.024-.06-.045-.123-.07-.183a6.721 6.721 0 00-.362-.75 4.076 4.076 0 012.213-.692z'
    ]
};
