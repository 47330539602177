import $ from 'jquery';
import scroll from '../hash-scroll';

$(() => {
    const faq = (function($, window, document, undefined) {
        const toggleDescription = function(el) {
            el.toggleClass('is-open');
            el.next('.js-faq-full-content').animate({ height: 'toggle' });

            // scroll opened to top
            if ($(el).length) {
                scroll(el);
            }
        };

        const triggerToggleDescription = function() {
            toggleDescription($(this));
        };

        const openTabOnInit = function() {
            const hash = window.location.hash.replace('#', '');

            if (hash) {
                toggleDescription($(`#a-${hash}`));
            }
        };

        const bindFunctions = function() {
            $('.js-faq-wrapper').on(
                'click',
                '.js-faq-intro',
                triggerToggleDescription
            );
        };

        const init = function() {
            openTabOnInit();
            bindFunctions();
        };

        return {
            init
        };
    })(jQuery, window, document);

    faq.init();
});
