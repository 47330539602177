import Swiper, {
    Navigation,
    Pagination,
    Lazy,
    Autoplay,
    A11y,
    EffectFade
} from 'swiper';

function initSlider() {
    const sliderHome = new Swiper('#slider-home', {
        modules: [Navigation, Pagination, Lazy, Autoplay, A11y, EffectFade],
        speed: 800,
        effect: 'fade',
        autoplay: {
            delay: 8000
        },
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        navigation: {
            nextEl: '#slider-home-arrow-next',
            prevEl: '#slider-home-arrow-prev'
        },
        pagination: {
            el: '#slider-home-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet(index, className) {
                return `<span class='${className}'><span class='swiper-pagination-bullet-inner'></span></span>`;
            }
        }
    });
}

if (document.getElementById('slider-home')) {
    initSlider();
}
