import Cookies from 'js-cookie';

const html = document.querySelector('html');

//
// wai contrast
//
const contrastButtonNode = document.getElementById('contrast-switch');
const contrasts = [
    { handle: 'default' },
    { handle: 'light' },
    { handle: 'dark' }
];

contrastButtonNode.addEventListener('click', e => {
    const currentContrast = html.dataset.contrast;
    const currentContrastIndex = contrasts.findIndex(
        item => item.handle === currentContrast
    );
    const lastContrastIndex = contrasts.length - 1;

    let nextContrastItem;
    if (currentContrastIndex < lastContrastIndex) {
        nextContrastItem = contrasts[currentContrastIndex + 1].handle;
    } else {
        nextContrastItem = contrasts[0].handle;
    }

    html.dataset.contrast = nextContrastItem;
    Cookies.set('contrast', nextContrastItem, { path: '/' });
});

//
// wai font
//
const fontSizeButtonNode = document.getElementById('font-switch');
const fontSizes = [
    { handle: 'default' },
    { handle: 'medium' },
    { handle: 'large' }
];

fontSizeButtonNode.addEventListener('click', e => {
    const currentFontSize = html.dataset.fontSize;
    const currentFontSizeIndex = fontSizes.findIndex(
        item => item.handle === currentFontSize
    );
    const lastFontSizeIndex = fontSizes.length - 1;

    let nextFontSizeItem;
    if (currentFontSizeIndex < lastFontSizeIndex) {
        nextFontSizeItem = fontSizes[currentFontSizeIndex + 1].handle;
    } else {
        nextFontSizeItem = fontSizes[0].handle;
    }

    html.dataset.fontSize = nextFontSizeItem;
    Cookies.set('font-size', nextFontSizeItem, { path: '/' });
});
