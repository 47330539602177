export default {
    prefix: 'custom',
    iconName: 'briefcase',
    icon: [
        28.773,
        30.828,
        [],
        null,
        'M14.387 0a11.66 11.66 0 00-4.57 1.135l-.569.285v3.906c-4.4.29-8.151.819-8.367.85L0 6.3v9.111A3.087 3.087 0 003.082 18.5h7.193v1.45l.301.3a5.452 5.452 0 003.809 1.328 5.452 5.452 0 003.814-1.326l.301-.3V18.5h7.19a3.087 3.087 0 003.083-3.082V6.301l-.882-.127v.002c-.216-.031-3.972-.56-8.368-.85V1.42l-.568-.285A11.66 11.66 0 0014.387 0zm-.004 2.055a8.955 8.955 0 013.084.674v2.484a69.93 69.93 0 00-3.084-.074 70.96 70.96 0 00-3.082.074V2.729a8.955 8.955 0 013.082-.674zm.004 5.138a107.522 107.522 0 0112.33.9v7.321a1.029 1.029 0 01-1.028 1.027H18.5V13.36h-8.225v3.082H3.082a1.029 1.029 0 01-1.027-1.027v-7.32a107.522 107.522 0 0112.332-.9zm-2.057 8.223h4.111v3.598a3.969 3.969 0 01-2.054.515 3.991 3.991 0 01-2.057-.513zM0 20.553v7.191a3.087 3.087 0 003.082 3.084h22.607a3.087 3.087 0 003.082-3.084v-7.191h-2.054l.002 7.191a1.029 1.029 0 01-1.03 1.03H3.082a1.029 1.029 0 01-1.027-1.03v-7.191z'
    ]
};
