export default {
    prefix: 'custom',
    iconName: 'magnifier',
    icon: [
        18.635,
        18.641,
        [],
        null,
        'M8.201 0a8.158 8.158 0 104.842 14.691l3.95 3.95 1.642-1.645-3.95-3.947A8.158 8.158 0 008.202 0zm-.03 2.377a5.808 5.808 0 015.817 5.809 5.807 5.807 0 01-5.808 5.806 5.808 5.808 0 01-.008-11.615z'
    ]
};
