import $ from 'jquery';
import isTouchDevice from '../is-touch-device';

$(() => {
    if (!isTouchDevice()) {
        if (!$('.toolbar-visible').length) {
            $('[data-toggle="tooltip"]').tooltip({
                trigger: 'hover'
            });
        }
    }
    
    if (!$('.toolbar-visible').length) {
        var $tooltipTrigger = $('[data-toggle="employee-tooltip"]').each(function() {
            var initialWidth = $(this).outerWidth(); // Get the initial width of the button
    
            $(this).css('width', initialWidth); // Set the initial width to maintain it
    
            $(this).tooltip({
                trigger: 'manual',
                template: '<div class="tooltip tooltip-employee" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            })
            .on('click', function() {
                $(this).tooltip('toggle'); // Manually toggle the tooltip on click
                $(this).text($(this).text() === 'Zobacz więcej' ? 'Zamknij' : 'Zobacz więcej'); // Toggle the button text
            });
        });
    
        $(document).on('click', function(e) {
            $tooltipTrigger.each(function() {
                // If the click is outside the current tooltip trigger and tooltip
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.tooltip').has(e.target).length === 0) {
                    $(this).tooltip('hide'); // Hide this tooltip
                    if ($(this).text() === 'Zamknij') {
                        $(this).text('Zobacz więcej'); // Reset the button text if tooltip was visible
                    }
                }
            });
        });
    }
    
    
    
});
