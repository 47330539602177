import $ from 'jquery';
import ouibounce from 'ouibounce';

$(document).ready(() => {
    const path = window.location.pathname;
    const url = window.location.href;

    if (
        (path.includes('/klient-indywidualny') &&
            !url.endsWith('/klient-indywidualny')) ||
        (path.includes('/klient-biznesowy') &&
            !url.endsWith('/klient-biznesowy'))
    ) {
        setTimeout(() => {
            window.ouibounceAPI = ouibounce(false, {
                timer: 0,
                cookieExpire: 1,
                sitewide: true,
                delay: 100,
                cookieName: 'exitPopupSeen',
                callback: async () => {
                    $('#userEngagementModal').modal('show');
                }
            });
        }, 10000);
    }

    const userEngagementFormTrigger = $('#userEngagementFormTrigger');
    const userEngagementFormHideTrigger = $('#userEngagementFormHideTrigger');
    const userEngagementFormWrapper = $('#userEngagementFormWrapper');

    function handleUserEngagementFormTrigger(e) {
        userEngagementFormWrapper.addClass('show');
    }

    function handleUserEngagementFormHideTrigger(e) {
        userEngagementFormWrapper.removeClass('show');
    }

    userEngagementFormTrigger.on('click', handleUserEngagementFormTrigger);
    userEngagementFormHideTrigger.on(
        'click',
        handleUserEngagementFormHideTrigger
    );

    // Form
    const form = $('#userEngagementForm');

    function getFormData(formElement) {
        const serializedData = formElement.serializeArray();
        const indexedArray = {};

        $.map(serializedData, (n, i) => {
            indexedArray[n.name] = n.value;
        });

        return indexedArray;
    }

    if (form.length) {
        const submitFormUrl = form.attr('data-submit-form-url');

        form.on('submit', event => {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
            const bodyData = getFormData(form);

            fetch(submitFormUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(bodyData)
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(data => {
                            throw new Error(data.message);
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    const { errors } = data;
                    const errorListNode = $('#userEngagementFormErrorList');
                    const errorListUlNode = errorListNode.find('ul');
                    const successBoxNode = $('#userEngagementFormSuccessBox');
                    errorListNode.hide();
                    errorListUlNode.html('');

                    if (errors && Object.keys(errors).length) {
                        $.each(errors, (key, value) => {
                            errorListUlNode.append(`<li>${value}</li>`);
                        });
                        errorListNode.show();
                    } else {
                        successBoxNode.show();
                        form.hide();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }
});
