function getRandomElement(max) {
    return Math.floor(Math.random() * max);
}

const historyAreaNode = document.querySelector('.history-area');

if (historyAreaNode) {
    const employeesPhotosWrapperNodeList = document.querySelectorAll(
        '.js-shuffle-employee'
    );

    const employeesPhotosWrapperNodeListCount =
        employeesPhotosWrapperNodeList.length;

    setTimeout(() => {
        setInterval(() => {
            const employeesPhotosWrapperNode =
                employeesPhotosWrapperNodeList[
                    getRandomElement(employeesPhotosWrapperNodeListCount)
                ];
            const employeesNodeList = employeesPhotosWrapperNode.querySelectorAll(
                'img'
            );
            const activeElement = employeesPhotosWrapperNode.querySelector(
                '.active'
            );
            let randomEmployeeNode =
                employeesNodeList[getRandomElement(employeesNodeList.length)];
            do {
                randomEmployeeNode =
                    employeesNodeList[
                        getRandomElement(employeesNodeList.length)
                    ];
            } while (randomEmployeeNode.classList.contains('active'));
            activeElement.classList.remove('active');
            randomEmployeeNode.classList.add('active');
        }, 2500);
    }, 5000);
}
