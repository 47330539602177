import $ from 'jquery';
import L from 'leaflet';
import responsivePopup from 'leaflet-responsive-popup';

$(() => {
    const mapContainer = document.getElementById('map-poi');

    if (mapContainer) {
        // Different settings based on screen size
        let zoom = 2.5;
        let minZoom = 1;
        let center = [0, 130];
        const dragging = true;

        if ($(window).width() >= 480) {
            // zoom = 2;
        }

        if ($(window).width() >= 768) {
            zoom = 3;
            minZoom = 3;
            center = [0, 130];
        }

        if ($(window).width() >= 768) {
            center = [0, 100];
        }

        if ($(window).width() >= 1860) {
            center = [0, 0];
            // dragging = false;
        }

        const map = L.map(mapContainer, {
            minZoom,
            maxZoom: 3,
            center,
            zoom,
            dragging,
            crs: L.CRS.Simple,
            scrollWheelZoom: false,
            zoomControl: false,
            attributionControl: false
        });

        // map.once('focus', () => {
        //     map.scrollWheelZoom.enable();
        // });

        // Image attributes
        const { url } = mapContainer.dataset;
        const { width } = mapContainer.dataset;
        const { height } = mapContainer.dataset;

        // Calculate the edges of the image, in coordinate space
        const southWest = map.unproject([0, height], map.getMaxZoom());
        const northEast = map.unproject([width, 0], map.getMaxZoom());
        const bounds = new L.LatLngBounds(southWest, northEast);

        // Add the image overlay, so that it covers the entire map
        L.imageOverlay(url, bounds).addTo(map);

        L.control
            .zoom({
                position: 'bottomright'
            })
            .addTo(map);

        // Tell leaflet that the map is exactly as big as the image
        map.setMaxBounds(bounds);

        const wMod = width / northEast.lng;
        const hMod = height / southWest.lat;

        // Coordinates in CRS.Simple take the form of [y, x] instead of [x, y], in the same way Leaflet uses [lat, lng] instead of [lng, lat].
        // Create wrappers for them
        const yx = L.latLng;
        const xy = function(x, y) {
            if (L.Util.isArray(x)) {
                // When doing xy([x, y]);
                return yx(x[1], x[0]);
            }
            return yx(y, x); // When doing xy(x, y);
        };

        // Create markers
        const items = document.querySelectorAll('.marker');
        for (let i = 0; i < items.length; i += 1) {
            const item = items[i];

            let { link } = item.dataset;
            let openInNewWindow = '';
            if (item.dataset.externalLink) {
                link = item.dataset.externalLink;
                openInNewWindow = 'target="_blank" rel="nofollow noopener"';
            }

            const data = {
                url: item.dataset.url,
                width:
                    window.innerWidth < 768
                        ? item.dataset.widthSmall
                        : item.dataset.width,
                height:
                    window.innerWidth < 768
                        ? item.dataset.heightSmall
                        : item.dataset.height,
                widthSmall: item.dataset.widthSmall,
                heightSmall: item.dataset.heightSmall,
                posx: item.dataset.posx,
                posy: item.dataset.posy,
                link: item.dataset.link,
                title: `<div class="leaflet-popup-inner">
                        <span class="leaflet-popup-inner-name">
                            ${item.dataset.title}
                        </span>
                        <span class="leaflet-popup-inner-address">
                            ${item.dataset.location}
                        </span>
                        <span class="leaflet-popup-inner-centrum">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--secondary)" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            </svg>
                            Do centrum: ${item.dataset.distanceFromCenter} km
                        </span>
                        <span class="leaflet-popup-inner-link">
                            <a href="${link}" ${openInNewWindow} class="btn btn-quaternary" target="_blank" rel="nofollow noopener">Wyznacz trasę</a>
                        </span>
                        </div>`
            };

            const markerIcon = L.icon({
                iconUrl: data.url,
                shadowUrl: false,
                iconSize: [data.width, data.height], // size of the icon
                iconAnchor: [data.width / 2, data.height], // point of the icon which will correspond to marker2's location
                shadowAnchor: [0, 0], // the same for the shadow
                popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
            });

            const marker = L.marker(xy([data.posx / wMod, data.posy / hMod]), {
                icon: markerIcon
            });

            marker.addTo(map);

            if (data.title) {
                const popup = L.responsivePopup({
                    offset: [40, 55],
                    autoPanPadding: [280, 80],
                    hasTip: false
                }).setContent(data.title);
                marker.bindPopup(popup); // .openPopup()
            }
        }
    }
});
