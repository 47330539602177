export default {
    prefix: 'custom',
    iconName: 'up-and-down',
    icon: [
        62.191,
        71.676,
        [],
        null,
        'M23.67 0L0 25.73h14.387v14.7H18.1V22.055H8.354L23.668 5.514l15.316 16.54H29.24v23.891H14.852l23.67 25.73 23.67-25.73H47.798V31.246h-3.713v18.377h9.748L38.518 66.164 23.2 49.623h9.748V25.73h14.387z'
    ]
};
