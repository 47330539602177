export default {
    prefix: 'custom',
    iconName: 'percent',
    icon: [
        50.262,
        50.288,
        [],
        null,
        'M9.654 0C4.31.039-.003 4.387 0 9.73c.035 5.35 4.37 9.682 9.723 9.714 5.397.025 9.784-4.356 9.765-9.754C19.47 4.292 15.052-.062 9.654 0zM36.14.009v3.495h8.183L.795 47.03l2.44 2.441L46.761 5.946v8.186h3.494V.008zM9.717 3.503c5.554-.002 8.33 6.698 4.406 10.623C10.2 18.05 3.5 15.278 3.498 9.727a6.277 6.277 0 016.219-6.224zm30.822 27.326c-8.654 0-12.993 10.475-6.873 16.595 6.12 6.12 16.596 1.782 16.596-6.875-.032-5.352-4.368-9.688-9.723-9.72zm-.07 3.496a6.222 6.222 0 016.297 6.22 6.277 6.277 0 01-6.221 6.225 6.224 6.224 0 01-.076-12.445z'
    ]
};
