import Swiper, { Pagination, Lazy, Autoplay, A11y } from 'swiper';

function initSlider(sliderID) {
    const sliderLogo = new Swiper(sliderID, {
        modules: [Pagination, Lazy, Autoplay, A11y],
        slidesPerView: 1,
        speed: 700,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        pagination: {
            el: ".swiper-pagination",
        },
    });
}

if (document.querySelector('.swiper-news')) {
    initSlider('.swiper-news');
}
