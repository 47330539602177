export default {
    prefix: 'custom',
    iconName: 'certificate',
    icon: [
        32.365,
        30.205,
        [],
        null,
        'M0 0v25.893h4.314v-2.159H2.158V2.158h28.049v21.576H16.184v2.159h16.181V0zm15.783 5.393A22.587 22.587 0 005.95 7.688L7 9.573a20.655 20.655 0 019.188-2.021 20.842 20.842 0 019.185 2.021l1.047-1.886a22.581 22.581 0 00-10.238-2.293 22.587 22.587 0 00-.399-.002zm-4.994 5.396v2.156h10.787V10.79zm0 4.29a5.394 5.394 0 00-3.236 9.708v5.418h6.472v-5.418a5.394 5.394 0 00-3.236-9.709zm-.059 2.185a3.237 3.237 0 013.295 3.236 3.241 3.241 0 01-3.236 3.236 3.237 3.237 0 01-.059-6.472zm8.69 1.078V20.5h6.473v-2.158zm-9.71 7.441a5.3 5.3 0 002.157 0v2.268H9.711z'
    ]
};
