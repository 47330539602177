import $ from 'jquery';
import scroll from '../hash-scroll';

const { hash } = window.location;
const hashString = hash.split('#').pop();

if (hash && $(`#pane-${hashString}`).length) {
    if ($(window).width() < 768) {
        // Open accordion content (mobile view)
        $(
            `.touch-device .accordion-header a[href="#collapse-${hashString}"][aria-expanded='false']`
        ).trigger('click');

        // Scroll to content
        setTimeout(() => {
            scroll($(`#pane-${hashString}`).get(0));
        }, 750);
    } else {
        // Open tab content (desktop view)
        $(`.nav-link[href="#pane-${hashString}"]`).trigger('click');

        // Scroll to content
        setTimeout(() => {
            scroll($(`.tabs-wrapper`).get(0));
        }, 750);
    }
}

// Change hash
$('[data-toggle="pill"]').on('shown.bs.tab', e => {
    window.history.pushState({}, '', `#${e.target.href.split('#pane-')[1]}`);
});
